import React, { useState, useEffect } from "react"
import { fetchPageContents } from "../components/utilities"
import Layout from "../components/layout"
import Seo from "../components/seo"

const History = () => {

  const [pageContents, setPageContents] = useState("");

  useEffect(() => {
    fetchPageContents("history")
      .then(text => setPageContents(text));
  },[]);

  return (
    <Layout>
      <Seo title="History of the Curran Index" />
      <div
        dangerouslySetInnerHTML={{__html: pageContents}}>
      </div>
    </Layout>
  )
}

export default History

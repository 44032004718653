export const capitalize = (string) => (
  string ? string[0].toUpperCase() + string.slice(1) : string
);

export const fetchPageContents = (pageKey) => (
  fetch(`https://admin.curranindex.org/items/page_contents?filter[page_key][_eq]=${pageKey}`)
    .then(response => {
      if (response.ok) {
        return response.json()
      }
      throw response
    })
    .then(data => (data.data[0].html))
    .catch(error => {
      console.error("Error fetching data: " + error);
    })
);

export const monthStrings = {
  "1": "Jan",
  "2": "Feb",
  "3": "Mar",
  "4": "Apr",
  "5": "May",
  "6": "Jun",
  "7": "Jul",
  "8": "Aug",
  "9": "Sep",
  "10": "Oct",
  "11": "Nov",
  "12": "Dec"
}
